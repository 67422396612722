/** @jsx jsx */
import { Fragment } from "react"
import { graphql, Link as GatsbyLink } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import { jsx, Container } from "theme-ui"
import { Heading } from "@theme-ui/components"
import { BouncingArrow, TheSeo } from "x"

const variants = {
	visible: { opacity: 1 },
	hidden: { opacity: 0 },
	reduced: { opacity: 0.5 },
}

// const MotionContainer = motion.custom(Container)

const HomePage = ({ data, location }) => (
	<Fragment>
		<TheSeo title="Search Listings" />
		<div
			sx={{
				display: "grid",
				alignItems: "center",
				height: "100%",
				position: "relative",
				zIndex: 1,
			}}
		>
			<Container>
				<motion.div
					initial="hidden"
					animate="visible"
					variants={variants}
					transition={{ ease: "easeInOut", duration: 2 }}
				>
					<Heading variant="homedisplay">
						Everything you need to find and lease office space in Manhattan,
						Brooklyn &amp; New York City.
					</Heading>

					<GatsbyLink
						to="/search-listings/"
						sx={{
							// fontFamily: theme.fonts.links,
							display: "inline-flex",
							alignItems: "center",
							color: "white",
							border: "3px solid #c41230",
							letterSpacing: "2px",
							textTransform: "uppercase",
							bg: "transparent",
							textAlign: "center",
							textDecoration: "none",
							m: 0,
							borderRadius: 0,
							fontSize: "18px",
							fontWeight: "500",
							px: 3,
							py: 3,
							lineHeight: 1,
						}}
					>
						Get Started <BouncingArrow />
					</GatsbyLink>
				</motion.div>
			</Container>
		</div>

		<motion.div
			initial="visible"
			animate="reduced"
			variants={variants}
			transition={{ ease: "easeInOut", duration: 3 }}
			sx={{
				position: "fixed",
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,
				backgroundColor: "#000",
			}}
		>
			<Img
				fluid={data.coverImage.childImageSharp.also}
				title="words"
				style={{ position: "unset", overflow: "visible" }}
				backgroundColor="#F9D6CE"
			/>
		</motion.div>
	</Fragment>
)

export default HomePage

export const Alsoquery = graphql`
	query {
		coverImage: file(relativePath: { regex: "/osg-bg-taxis/" }) {
			childImageSharp {
				also: fluid(quality: 80) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`
